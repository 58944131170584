/* eslint-disable no-restricted-globals */
/**
 * Handles dev functionality for the sso-compatibility client.
 */

const hideCompatibilityPaths = ['/logout', '/get-org'];

// Hide link to compatibility mode if on logout or get-org pages, or if not on localhost
(function () {
if (
    hideCompatibilityPaths.includes(location.pathname)
) {
    document.getElementById('compatibility-mode-link').hidden = true;
}
})();

// When visiting development sso-frontend, use absolute path for link
(function () {
if (location.host === 'localhost:1234') {
    document.getElementById('compatibility-mode-link').href =
    'http://localhost:4001/compatibility-mode';
}
})();